import { createBrowserRouter } from "react-router-dom";

import Login from "./pages/Login/Login";
import ErrorPage from "./pages/errorPages";
import StepOneAccountCreation from "./pages/AccountCreation/StepOneAccountCreation/StepOneAccountCreation";
import StepTwoAccountCreation from "./pages/AccountCreation/StepTwoAccountCreation/StepTwoAccountCreation";
import IntegrationAccountCreation from "./pages/AccountCreation/IntegrationAccountCreation/IntegrationAccountCreation";
import Onboarding from "./pages/Onboarding/Onboarding";

import Dashboard from "./pages/Dashboard/Dashboard";
import Product from "./pages/Product";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";

import CreatePlan from "./pages/CreatePlan/CreatePlan";

import PrivateRouter from "./security/PrivateRoute";
import Otb from "./pages/Otb/Otb";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/forgotPassword",
    element: <ForgotPassword />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/resetPassword",
    element: <ResetPassword />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/createAccount",
    element: <StepOneAccountCreation />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/createAccountNext",
    element: <StepTwoAccountCreation />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/createAccountIntegration",
    element: <IntegrationAccountCreation />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/onboarding",
    element: <Onboarding />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/home",
    element: (
      <PrivateRouter>
        <Dashboard />
      </PrivateRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/product/:id",
    element: <Product />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/createPlan",
    element: (
      <PrivateRouter>
        <CreatePlan />
      </PrivateRouter>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/otb",
    element: <Otb />,
    errorElement: <ErrorPage />,
  },
]);

export default router;
