import styled from "styled-components";

const form = styled.form`
  width: 350px;
  gap: 25px;
  display: grid;
  .submitBtn {
    background-color: #48fa7f;
    color: #000;
    &:hover {
      opacity: 0.8;
    }
  }
`;

export { form };
