import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ShoppingCart,
  Flash,
  HealthCross,
  Recycle,
  Rocket,
  FlashFilled,
  Box,
} from "@carbon/icons-react";

import * as S from "./table.style";
import ModalDetalhe from "../ModelDetalhe/ModalDetalhe";
import { BoxFilled, HealthCrossFilled, RocketFilled } from "../LocalIcons";

const listHeader = [
  { title: "#", MultLines: false },
  { title: "SKU", MultLines: false },
  {
    title: "Perf. de Venda",
    MultLines: true,
    percent: 47,
    icon: "Rocket",
  },
  {
    title: "Vel. de Venda",
    MultLines: true,
    percent: 38,
    icon: "Flash",
  },
  {
    title: "Saúde de Estoque",
    MultLines: true,
    percent: 57,
    icon: "HealthCross",
  },
  {
    title: "Cob. de Estoque",
    MultLines: true,
    percent: 87,
    icon: "Box",
  },
  { title: "Receita (R$)", MultLines: false },
  { title: "Receita (Share)", MultLines: false },
  { title: "Cresc. Receita vs Último Ano", MultLines: false },
  { title: "Preço Médio de Venda (R$)", MultLines: false },
  { title: "Share Valor de Venda (%)", MultLines: false },
  { title: "Estoque (Pçs)", MultLines: false },
  { title: "Estoque (R$)", MultLines: false },
  { title: "Cobertura (Dias)", MultLines: false },
];

const listBody = [
  {
    id: 1,
    sku: { sku: "00000000001", name: "Air Runner", corSize: "Branca / PP" },
    performance: "Crítica",
    receita: "564",
    receitaShare: "0.8",
    crescReceita: "0.1",
    precoVenda: "120.00",
    sharePrecoVenda: "12.00",
    estoquePÇ: "2000",
    estoqueRS: "120.00",
    cobertura: "60",
  },
  {
    id: 2,
    sku: {
      sku: "00000000002",
      name: "Air Runner",
      corSize: "Branca / P",
    },
    performance: "Ótimo",
    receita: "2000",
    receitaShare: "12.90",
    crescReceita: "40.23",
    precoVenda: "250.00",
    sharePrecoVenda: "35.24",
    estoquePÇ: "800",
    estoqueRS: "1.258",
    cobertura: "60",
  },
  {
    id: 3,
    sku: {
      sku: "00000000003",
      name: "Air Runner",
      corSize: "Branca / M",
    },
    performance: "Regular",
    receita: "1.564",
    receitaShare: "11.81",
    crescReceita: "31.08",
    precoVenda: "523.00",
    sharePrecoVenda: "24.76",
    estoquePÇ: "1.523",
    estoqueRS: "5.512",
    cobertura: "60",
  },
  {
    id: 4,
    sku: {
      sku: "00000000004",
      name: "Air Runner",
      corSize: "Branca / G",
    },
    performance: "Regular",
    receita: "1.564",
    receitaShare: "15.81",
    crescReceita: "33.08",
    precoVenda: "320.00",
    sharePrecoVenda: "60.76",
    estoquePÇ: "11.523",
    estoqueRS: "500.512",
    cobertura: "60",
  },
  {
    id: 5,
    sku: {
      sku: "00000000005",
      name: "Air Runner",
      corSize: "Branca / GG",
    },
    performance: "Excelente",
    receita: "3.158",
    receitaShare: "40.81",
    crescReceita: "90.08",
    precoVenda: "250.00",
    sharePrecoVenda: "67.76",
    estoquePÇ: "12.523",
    estoqueRS: "298.512",
    cobertura: "60",
  },
];

function Table({ type }) {
  const [startModal, setStartModal] = useState(false);
  const [valueSendModal, setValueSendModal] = useState(null);
  const accordingStatus = (status, Icons, FilledIcons) => {
    switch (status) {
      case "Excelente": {
        return (
          <div className="icons">
            <span className="text-status-icon">{status}</span>
            <FilledIcons />
            <FilledIcons />
            <FilledIcons />
            <FilledIcons />
          </div>
        );
      }
      case "Ótimo": {
        return (
          <div className="icons">
            <span className="text-status-icon">{status}</span>
            <FilledIcons />
            <FilledIcons />
            <FilledIcons />
            <Icons />
          </div>
        );
      }
      case "Regular": {
        return (
          <div className="icons">
            <span className="text-status-icon">{status}</span>
            <FilledIcons />
            <FilledIcons />
            <Icons />
            <Icons />
          </div>
        );
      }
      case "Crítica": {
        return (
          <div className="icons">
            <span className="text-status-icon">{status}</span>
            <FilledIcons />
            <Icons />
            <Icons />
            <Icons />
          </div>
        );
      }

      default: {
        return null;
      }
    }
  };

  const buildColumnIcons = (type, value) => {
    if (type === "performance") {
      return (
        <S.tdMultLine>
          {accordingStatus(value, Rocket, RocketFilled)}
        </S.tdMultLine>
      );
    }
    if (type === "velocidade") {
      return (
        <S.tdMultLine>
          {accordingStatus(value, Flash, FlashFilled)}
        </S.tdMultLine>
      );
    }
    if (type === "saude") {
      return (
        <S.tdMultLine>
          {accordingStatus(value, HealthCross, HealthCrossFilled)}
        </S.tdMultLine>
      );
    }
    if (type === "cobertura") {
      return (
        <S.tdMultLine>{accordingStatus(value, Box, BoxFilled)}</S.tdMultLine>
      );
    }
    return null;
  };

  const showRenderIcon = (icon) => {
    switch (icon) {
      case "Rocket": {
        return <Rocket />;
      }
      case "Flash": {
        return <Flash />;
      }
      case "HealthCross": {
        return <HealthCross />;
      }
      case "Box": {
        return <Box />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <S.table>
      <S.theader>
        <S.tr>
          {listHeader.map((item, index) => {
            if (item.title === "#") {
              return (
                <S.th key={item.title} size={48}>
                  {item.title}
                </S.th>
              );
            }

            if (item.MultLines) {
              return (
                <S.thTwoLine key={item.title}>
                  <span>{item.title}</span>
                  <div className="headerTwoLinePercent">
                    <span>{item.percent}%</span> {showRenderIcon(item.icon)}
                  </div>
                </S.thTwoLine>
              );
            }
            return <S.th key={item.title}>{item.title}</S.th>;
          })}
        </S.tr>
      </S.theader>
      <S.tbody>
        {listBody.map(
          ({
            id,
            sku,
            performance,
            receita,
            receitaShare,
            crescReceita,
            precoVenda,
            sharePrecoVenda,
            estoquePÇ,
            estoqueRS,
            cobertura,
          }) => (
            <S.tr
              key={id}
              color
              onClick={() => {
                setStartModal(true);
                setValueSendModal({
                  type,
                  sku,
                  performance,
                  receita,
                  receitaShare,
                  crescReceita,
                  precoVenda,
                  sharePrecoVenda,
                  estoquePÇ,
                  estoqueRS,
                  cobertura,
                });
              }}
            >
              <S.td>{id}</S.td>
              <S.td>
                <S.tdMultLine>
                  <div className="lineColor">{sku.sku}</div>
                  <strong>
                    <div>{sku.name}</div>
                  </strong>
                  <div>{sku.corSize}</div>
                </S.tdMultLine>
              </S.td>
              <S.td>{buildColumnIcons("performance", performance)}</S.td>
              <S.td>{buildColumnIcons("velocidade", performance)}</S.td>
              <S.td>{buildColumnIcons("saude", performance)}</S.td>
              <S.td>{buildColumnIcons("cobertura", performance)}</S.td>
              <S.td>{receita}</S.td>
              <S.td>{receitaShare}</S.td>
              <S.td>{crescReceita}</S.td>
              <S.td>{precoVenda}</S.td>
              <S.td>{sharePrecoVenda}</S.td>
              <S.td>{estoquePÇ}</S.td>
              <S.td>{estoqueRS}</S.td>
              <S.td>{cobertura}</S.td>
            </S.tr>
          )
        )}
      </S.tbody>
      {valueSendModal && (
        <ModalDetalhe
          open={startModal}
          item={valueSendModal}
          setShow={setStartModal}
        />
      )}
    </S.table>
  );
}

Table.propTypes = {
  type: PropTypes.string,
};

export default Table;
