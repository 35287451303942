import React, { useState } from "react";
import { Checkbox, Column, Grid, TextInput } from "@carbon/react";
import {
  ArrowRight,
  ArrowLeft,
  DeliveryParcel,
  Mobile,
  Store,
} from "@carbon/icons-react";

import Icon from "../../../assets/icons/Shopping--cart.svg";
import * as S from "./SalesChannelStep.style";

export default function SalesChannelStep({ onCompleteStep, onBackStep }) {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <Grid style={{ marginTop: 60 }}>
      <Column lg={8} md={4} sm={4}>
        <S.Title>
          <img src={Icon} alt="Canais de venda" />
          <h4>Canais de venda</h4>
        </S.Title>
        <S.Text>
          Você pode separar o seu planejamento de acordo com os canais de venda
          que sua empresa atua. Isso traz a possibilidade de analisar as vendas
          e datas de recebimentos especificamente para cada um deles.
        </S.Text>
      </Column>
      <Column
        lg={8}
        md={4}
        sm={4}
        style={{
          backgroundColor: "#0000000A",
          padding: "24px 72px",
        }}
      >
        <S.Text>
          Liste os canais de venda da sua empresa e informe a meta de
          participação de cada um deles.
        </S.Text>
        <S.Check>
          <Checkbox
            id="check"
            labelText="Não quero separar em canais de venda"
            checked={isChecked}
            onChange={(_, { checked }) => setIsChecked(checked)}
          />
        </S.Check>
        <S.List>
          <Checkbox
            id="checkStore"
            disabled={isChecked}
            labelText={
              <>
                <Store /> Loja Física
              </>
            }
          />
          <TextInput placeholder="Participação da Meta" />
          <Checkbox
            id="checkDigital"
            disabled={isChecked}
            labelText={
              <>
                <Mobile /> Digital
              </>
            }
          />
          <TextInput placeholder="Participação da Meta" />
          <Checkbox
            id="checkAtacado"
            disabled={isChecked}
            labelText={
              <>
                <DeliveryParcel /> Atacado
              </>
            }
          />
          <TextInput placeholder="Participação da Meta" />
        </S.List>
        <S.ButtonSet>
          <S.Button
            kind="secondary"
            onClick={() => {
              onBackStep(1);
            }}
          >
            <ArrowLeft /> Voltar
          </S.Button>
          <S.Button
            primary
            renderIcon={() => <ArrowRight />}
            onClick={() => {
              onCompleteStep(1);
            }}
          >
            Próxima
          </S.Button>
        </S.ButtonSet>
      </Column>
    </Grid>
  );
}
