import React from "react";
import { TextInput, Link } from "@carbon/react";
import { ChevronRight } from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";

import LayoutWithoutMenu from "../../../layouts/LayoutWithoutMenu/LayoutWithoutMenu";
import ButtonDefault from "../../../components/ButtonDefault/ButtonDefault";
import ButtonGoogle from "../../../components/ButtonGoogle/ButtonGoogle";

import * as S from "./stepOneAccountCreation.style";

const TextInputProps = {
  type: "email",
  id: "TextEmail",
  placeholder: "E-mail",
  labelText: "",
  invalidText: "E-mail invalido",
  // pattern: /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/,
  invalid: false,
  required: true,
};
const PasswordProps = {
  id: "TextPassword",
  labelText: "",
  placeholder: "Senha",
  autoComplete: true,
  invalid: false,
  invalidText: "Senha invalido",
  // pattern: "(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{6,}",
  required: true,
  helperText: "Mínimo 8 caractéres.",
};
const ConfirmPasswordProps = {
  id: "TextPasswordConfirm",
  labelText: "",
  placeholder: "Confirme a Senha",
  autoComplete: true,
  invalid: false,
  invalidText:
    "Confirmar senha A senha está diferente da digitada anteriormente",
  // pattern: "(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{6,}",
  required: true,
};

export default function StepOneAccountCreation() {
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/createAccountNext");
  };

  return (
    <LayoutWithoutMenu>
      <>
        <ButtonGoogle />
        <S.contentPhaseOr>
          <i />
          <span>ou</span>
          <i />
        </S.contentPhaseOr>

        <S.form onSubmit={handleSubmit}>
          <TextInput {...TextInputProps} />
          <TextInput.PasswordInput {...PasswordProps} />
          <TextInput.PasswordInput
            {...ConfirmPasswordProps}
            // onChange={(e) => {
            //   setConfirPassword(e.target.value);
            // }}
            // invalid={erroStatusPasswordEg}
          />
          <ButtonDefault
            type="submit"
            className="submitBtn"
            renderIcon={() => <ChevronRight />}
          >
            Criar conta
          </ButtonDefault>
          <div className="termsAndPolicy">
            Ao clicar em "Criar conta" ou "Continuar com o Google", você
            concorda com os
            <Link className="link" href="/#">
              Termos de Uso
            </Link>
            e a
            <Link className="link" href="/#">
              Política de Privacidade.
            </Link>
          </div>
        </S.form>

        <S.haveAccount>
          Já possui um conta?
          <Link className="" href="/">
            Login
          </Link>
        </S.haveAccount>
      </>
    </LayoutWithoutMenu>
  );
}
