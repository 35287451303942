import styled from "styled-components";

const contentBtnGoogle = styled.div`
  margin-bottom: 20px;
`;

const contentPhaseOr = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 15px;
  span {
    margin: 0 12px;
  }
  i {
    background-color: #a8a8a8;
    width: 88px;
    height: 2px;
  }
`;
const form = styled.form`
  width: 350px;
  gap: 25px;
  display: grid;
  .submitBtn {
    background-color: #48fa7f;
    color: #000;
    &:hover {
      opacity: 0.8;
    }
  }
  .termsAndPolicy {
    justify-content: center;
    font-size: 12px;
    a {
      color: #05b33a !important;
      font-size: 12px;
      margin: 0 5px;
    }
  }
`;

const haveAccount = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;

  a {
    color: #05b33a !important;
    margin: 0 5px;
  }
`;

export { contentBtnGoogle, contentPhaseOr, form, haveAccount };
