import styled from "styled-components";
import { globalStyles } from '../../consts/globalStyles';
const Container = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto 1fr;
`;

const Header = styled.div`
position: relative;
 img {
  position: absolute;
  left: ${globalStyles.size.lg};
  top: ${globalStyles.size.lg};
 }
`;

const Main = styled.div`
  display: grid;
  justify-content: center;
`;

const Footer = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column-reverse;
`;

export {
  Container,
  Header,
  Main,
  Footer,
};
