import CryptoJS from 'crypto-js';

export function generateToken(password) {
  try {
    const encrypted = CryptoJS.AES.encrypt(password, 'secretkey123').toString();

    localStorage.setItem('token', encrypted);
    return true  
  } catch (error) {
    console.log(error);
    return false
  }
  
}
