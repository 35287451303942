import { DataAnalytics } from "@carbon/icons-react";
import * as S from "./GeneralView.style";
import { faker } from "@faker-js/faker/locale/af_ZA";

const OverTag = ({ isOver }) => {
  return isOver ? (
    <S.OverTag isOver>Estoque em excesso</S.OverTag>
  ) : (
    <S.OverTag>Estoque em falta</S.OverTag>
  );
};

const mockAmount = () => faker.number.int(2500);

export default function GeneralView({ onNavTab }) {
  const overItems = [
    { category: "Polo", amount: mockAmount() },
    { category: "Acessório", amount: mockAmount() },
    { category: "Acessório", amount: mockAmount() },
    { category: "Acessório", amount: mockAmount() },
    { category: "Acessório", amount: mockAmount() },
    { category: "Acessório", amount: mockAmount() },
  ];
  const lackItems = [
    { category: "Polo", amount: mockAmount() },
    { category: "Polo", amount: mockAmount() },
    { category: "Polo", amount: mockAmount() },
    { category: "Polo", amount: mockAmount() },
    { category: "Polo", amount: mockAmount() },
    { category: "Polo", amount: mockAmount() },
  ];
  const overTotalItems = overItems.reduce((acc, { amount }) => acc + amount, 0);
  const lackTotalItems = lackItems.reduce((acc, { amount }) => acc + amount, 0);
  const activeRecommendations = faker.number.int(300);
  const totalItems = overTotalItems + lackTotalItems;
  return (
    <S.Grid>
      <S.Link type="right-bottom" />
      <S.Link type="horizontal" />
      <S.Card center>
        <h6>{totalItems.toLocaleString("pt-br")}</h6>
        <p>peças para ajuste</p>
      </S.Card>
      <S.Link type="horizontal" />
      <S.Link type="left-bottom" />

      <S.Link type="vertical" />
      <S.Link />
      <S.Card center gray>
        <h6>{activeRecommendations.toLocaleString("pt-br")}</h6>
        <p>ações sugeridas</p>
        <S.Button onClick={() => onNavTab()}>
          Analisar <DataAnalytics />
        </S.Button>
      </S.Card>
      <S.Link />
      <S.Link type="vertical" />

      <S.Card center>
        <OverTag isOver />
        <h6 style={{ marginTop: 8 }}>
          +{overTotalItems.toLocaleString("pt-br")}
        </h6>
      </S.Card>
      <S.Link />
      <S.Link />
      <S.Link />
      <S.Card center>
        <OverTag />
        <h6 style={{ marginTop: 8 }}>
          -{lackTotalItems.toLocaleString("pt-br")}
        </h6>
      </S.Card>

      <S.Link type="vertical" />
      <S.Link />
      <S.Link />
      <S.Link />
      <S.Link type="vertical" />

      <S.GroupCard>
        {overItems.map(({ category, amount }) => (
          <S.Card>
            <h3>Categoria</h3>
            <h4>{category}</h4>
            <div className="price">
              <OverTag isOver />
              <h6>+{amount}</h6>
            </div>
          </S.Card>
        ))}
      </S.GroupCard>
      <S.Link />
      <S.Link />
      <S.Link />
      <S.GroupCard>
        {lackItems.map(({ category, amount }) => (
          <S.Card>
            <h3>Categoria</h3>
            <h4>{category}</h4>
            <div className="price">
              <OverTag />
              <h6>-{amount}</h6>
            </div>
          </S.Card>
        ))}
      </S.GroupCard>
    </S.Grid>
  );
}
