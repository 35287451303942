import React, { useState } from "react";
import {
  Column,
  RadioButtonGroup,
  RadioButton,
  Form,
  Stack,
  Select,
  SelectItem,
  NumberInput,
} from "@carbon/react";
import { ChevronRight } from "@carbon/icons-react";
import LayoutWithMenu from "../../layouts/LayoutWithMenus/LayoutWithMenus";
import Label from "../../components/Label/Label";

import * as S from "./onboarding.style";
import { useNavigate } from "react-router-dom";

const radioGroup = {
  invalidText: "Invalid selection",
  warn: false,
  warnText: "Please notice the warning",
};
export default function Onboarding() {
  const [showOption, setShowOption] = useState(true);
  const navigate = useNavigate();

  const submitFinalizerOnboarding = (e) => {
    console.log(e);
    if (e.type === "submit") {
      e.preventDefault();
    }
    navigate("/home");
  };
  return (
    <LayoutWithMenu isMenu={false}>
      <Column lg={{ offset: 2, span: 6 }}>
        <Stack gap={6}>
          <h2>Você está a um passo de melhorar seus resultados!</h2>
          <p>
            A <b>Projeção de Crescimento</b> é tudo que precisamos para começar
            a impactar positivamente o seu negócio.
          </p>
        </Stack>

        <S.formRadio>
          <Stack gap={6}>
            <Label>Selecione o método que seja utilizar:</Label>

            <RadioButtonGroup
              name="radio-button-group"
              orientation="vertical"
              defaultSelected="manual"
              {...radioGroup}
            >
              <RadioButton
                value="manual"
                id="manual"
                labelText="Definir Manualmente"
                className="radio"
                onClick={() => setShowOption(!showOption)}
              />
              <RadioButton
                value="automatico"
                id="automatico"
                labelText="Usar Índice de Inflação"
                className="radio"
                onClick={() => setShowOption(!showOption)}
              />
            </RadioButtonGroup>
          </Stack>
        </S.formRadio>
      </Column>
      <Column lg={{ span: 6, start: 10 }}>
        {showOption && (
          <>
            <Stack gap={6}>
              <h5>Definir Manualmente</h5>
              <S.code>
                Insira a projeção do valor ou do percentual que você deseja
                crescer.
              </S.code>
            </Stack>
            <Form onSubmit={submitFinalizerOnboarding}>
              <Stack gap={7}>
                <S.formGroup>
                  <Select id="select-tipo" labelText="Tipo" defaultValue="real">
                    <SelectItem value="real" text="R$" />
                    <SelectItem value="Option 3" text="Option 3" />
                    <SelectItem value="Option 4" text="Option 4" />
                  </Select>
                  <NumberInput
                    id="number-valor"
                    min={0}
                    max={99999999999999}
                    value={50}
                    label="Valor"
                  />
                </S.formGroup>
                <S.ButtonGreen
                  renderIcon={() => <ChevronRight />}
                  type="submit"
                >
                  Finalizar
                </S.ButtonGreen>
              </Stack>
            </Form>
          </>
        )}
        {!showOption && (
          <>
            <Stack gap={7}>
              <h5>Usar Índice de Inflação</h5>
              <S.code>
                O índice de inflação é atualizado conforme site{" "}
                <S.Link href="https://www.bcb.gov.br">
                  https://www.bcb.gov.br
                </S.Link>
              </S.code>
              <S.infoCard>12.7%</S.infoCard>
              <S.ButtonGreen
                renderIcon={() => <ChevronRight />}
                type="button"
                onClick={submitFinalizerOnboarding}
              >
                Finalizar
              </S.ButtonGreen>
            </Stack>
          </>
        )}
      </Column>
    </LayoutWithMenu>
  );
}
