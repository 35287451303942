import React, { useState } from "react";
import { Column, TabList, TabPanel, TabPanels, Tabs } from "@carbon/react";
import { CheckmarkFilled } from "@carbon/icons-react";
import { useNavigate } from "react-router-dom";

import LayoutWithMenu from "../../layouts/LayoutWithMenus/LayoutWithMenus";
import * as S from "./CreatePlan.style";
import CreateSeasonStep from "./CreateSeasonStep/CreateSeasonStep";
import SalesChannelStep from "./SalesChannelStep/SalesChannelStep";
import LeadTimeStep from "./LeadTimeStep/LeadTimeStep";
import CommercialDaysStep from "./CommercialDaysStep/CommercialDaysStep";
import SalesGraphStep from "./SalesGraphStep/SalesGraphStep";

export default function CreatePlan() {
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [navigation, setNavigation] = useState([
    {
      title: "Período de planejamento",
      completed: false,
      disabled: false,
    },
    {
      title: "Canais de venda",
      completed: false,
      disabled: true,
    },
    {
      title: "Lead time",
      completed: false,
      disabled: true,
    },
    {
      title: "Datas comerciais",
      completed: false,
      disabled: true,
    },
    {
      title: "Curva de venda",
      completed: false,
      disabled: true,
    },
  ]);

  const backStep = (index) => {
    setSelectedIndex(index - 1);
  };

  const completeStep = (index) => {
    console.log(index);
    setSelectedIndex(index + 1);
    setNavigation((prev) =>
      prev.map((item, i) => {
        if (i === index) {
          return { ...item, completed: true };
        }
        if (i === index + 1) {
          return { ...item, disabled: false };
        }
        return item;
      })
    );
  };

  return (
    <LayoutWithMenu isCenter={false}>
      <Column lg={16} md={8} sm={4}>
        <S.Section>
          <S.Title>
            <h3>Criar Planejamento</h3>
          </S.Title>

          <Tabs selectedIndex={selectedIndex}>
            <TabList fullWidth style={{ flex: 1 }}>
              {navigation.map((item, index) => (
                <S.Tab
                  key={index}
                  completed={item.completed}
                  renderIcon={item.completed ? CheckmarkFilled : null}
                  disabled={item.disabled}
                >
                  {item.title}
                </S.Tab>
              ))}
            </TabList>
            <TabPanels>
              <TabPanel>
                <CreateSeasonStep onCompleteStep={completeStep} />
              </TabPanel>
              <TabPanel>
                <SalesChannelStep
                  onCompleteStep={completeStep}
                  onBackStep={backStep}
                />
              </TabPanel>
              <TabPanel>
                <LeadTimeStep
                  onCompleteStep={completeStep}
                  onBackStep={backStep}
                />
              </TabPanel>
              <TabPanel>
                <CommercialDaysStep
                  onCompleteStep={completeStep}
                  onBackStep={backStep}
                />
              </TabPanel>
              <TabPanel>
                <SalesGraphStep
                  onCompleteStep={() => navigate("/otb")}
                  onBackStep={backStep}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </S.Section>
      </Column>
    </LayoutWithMenu>
  );
}
