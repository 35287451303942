import styled, { css } from "styled-components";
import { Button as CButton, Table as CTable } from "@carbon/react";

export const Section = styled.section``;

export const Title = styled.article`
  h4 {
    font-size: 24px;
    font-weight: 600;
  }

  display: flex;
  gap: 24px;
  padding: 24px 0;
  align-items: center;
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #7d7d7d;
`;

export const Button = styled(CButton)`
  align-items: center;
  display: flex;
  flex-grow: 1;
  padding-inline-end: 10px;
  max-inline-size: 100%;

  .icon {
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }

  &:hover {
    opacity: 0.8;
  }

  ${(props) => {
    if (props.primary) {
      return css`
        background-color: #75fb9f;
        color: #000;
      `;
    }

    if (props.kind === "tertiary") {
      return css`
        color: #000;
        border-color: #000;
      `;
    }

    if (props.kind === "ghost") {
      return css`
        color: #636569;
      `;
    }
  }}
`;

export const ButtonSet = styled.div`
  gap: 24px;
  padding: 24px 0;
  display: flex;
`;

export const Check = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  padding: 24px 0;
`;

export const List = styled.div`
  align-items: center;
  display: flex;
  padding: 8px 0;

  input {
    background-color: white;
    border: none;
  }
`;
export const Table = styled(CTable)`
  th {
    background-color: black !important;
    color: white !important;
    border-color: gray;
  }

  td {
    background-color: white !important;
    padding: 16px;
    font-weight: 600;

    input {
      border: none;
    }
  }
`;

export const Product = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #777a7e;
  margin: 8px 0;
`;