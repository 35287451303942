const selectItemsCompany = [
  { item: "Auto Peças e Serviços Automotivos", valor: 1 },
  { item: "Cosméticos e Higiene Pessoal", valor: 2 },
  { item: "Drogarias e Farmácias", valor: 3 },
  { item: "Estética e Cabeleireiros", valor: 4 },
  { item: "Franquias", valor: 5 },
  { item: "Livrarias Papelarias e Afins", valor: 6 },
  { item: "Materiais para Construção", valor: 7 },
  { item: "Móveis Eletro e Depto.", valor: 8 },
  { item: "Óticas e Joalherias", valor: 9 },
  { item: "Outros Serviços", valor: 10 },
  { item: "Outros Varejos", valor: 11 },
  { item: "Postos de Gasolina", valor: 12 },
  { item: "Recreação e Lazer", valor: 13 },
  { item: "Supermercados e Hipermercados", valor: 14 },
  { item: "Turismo e Transporte", valor: 15 },
  { item: "Varejo Alimentício Especializado", valor: 16 },
  { item: "Vestuário e/ou Artigos Esportivos", valor: 17 },
  { item: "Veterinárias e Pet-shops", valor: 18 }
];

const selectItemsEmployees =  [
  { item: "1-9", valor: 1 },
  { item: "10-49", valor: 2 },
  { item: "50-99", valor: 3 },
  { item: "100 ou mais", valor: 4 }
];

export {
  selectItemsCompany,
  selectItemsEmployees
}