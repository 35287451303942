import React from "react";
import Card from "../../components/Card/Card";

import LayoutWithMenu from "../../layouts/LayoutWithMenus/LayoutWithMenus";
import * as S from "./dashboard.style";
import Table from "../../components/Table/Table";
import {
  BuildingInsights_3,
  Currency,
  DeliveryParcel,
  Filter,
} from "@carbon/icons-react";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Dropdown } from "@carbon/react";

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartDataLabels);

export default function Dashboard() {
  const temporadas = [
    { id: "current", text: "Atual - 01/01/2023 a 31/12/2023" },
  ];
  const categorias = [{ id: "all", text: "Todas" }];
  const canais = [{ id: "all", text: "Todos" }];
  return (
    <LayoutWithMenu isMenu isCenter={false}>
      <S.title border>
        <h3>Oportunidades</h3>
      </S.title>
      <S.section>
        <S.section className="filterWrapper">
          <div className="filters">
            <span className="sub">Temporadas</span>
            <Dropdown
              id="temporadas"
              type="inline"
              label="Temporadas"
              items={temporadas}
              itemToString={(item) => (item ? item.text : "")}
              selectedItem={temporadas[0]}
            />
            <span className="sub">Categorias</span>
            <Dropdown
              id="categorias"
              type="inline"
              label="Categorias"
              items={categorias}
              itemToString={(item) => (item ? item.text : "")}
              selectedItem={categorias[0]}
            />
            <span className="sub">Canais</span>
            <Dropdown
              id="canais"
              type="inline"
              label="Canais"
              items={canais}
              itemToString={(item) => (item ? item.text : "")}
              selectedItem={canais[0]}
            />
          </div>
        </S.section>
        <S.section center style={{ marginTop: "15px" }}>
          <Card
            icon={<BuildingInsights_3 size={24} color="#777A7E" />}
            title="Negócio"
            tag="regular"
            score={2}
            bigInfo="36%"
            parent
          />
        </S.section>
        <S.section center row style={{ alignItems: "flex-start" }}>
          <S.section center style={{ width: "50%", padding: "0 16px" }}>
            <Card
              icon={<Currency size={24} color="#777A7E" />}
              title="Vendas"
              tag="ótimo"
              score={3}
              bigInfo="50%"
              parent
              child
            />
            <S.section center row>
              <Card
                icon={<Currency size={24} color="#777A7E" />}
                subtitle="Fator"
                title="Performance"
                tag="ótimo"
                score={3}
                bigInfo="50%"
                child
              />
              <Card
                icon={<DeliveryParcel size={24} color="#777A7E" />}
                subtitle="Fator"
                title="Velocidade"
                tag="regular"
                score={1}
                bigInfo="50%"
                child
              />
            </S.section>
            <S.section row>
              <Card
                subtitle="Venda em Valor"
                title="Realizada x Projetada (R$)"
                content={
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      gridTemplateColumns: "4fr 1fr",
                    }}
                  >
                    <ProgressBar label="R$ 1.600.00 (50%)" completed={50} />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "4px",
                      }}
                    >
                      <span style={{ fontSize: "12px" }}>Projetado</span>
                      <span>R$ 2.200.000</span>
                    </div>
                  </div>
                }
              />
            </S.section>
            <S.section row>
              <Card
                subtitle="Venda em Unidades"
                title="Realizada x Projetada"
                content={
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      gridTemplateColumns: "4fr 1fr",
                    }}
                  >
                    <ProgressBar label="R$ 1.000.00 (33%)" completed={33} />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "4px",
                      }}
                    >
                      <span style={{ fontSize: "12px" }}>Projetado</span>
                      <span>R$ 3.000.000</span>
                    </div>
                  </div>
                }
              />
            </S.section>
          </S.section>
          <S.section center style={{ width: "50%", padding: "0 16px" }}>
            <Card
              icon={<Currency size={24} color="#777A7E" />}
              title="Estoque"
              tag="crítico"
              score={1}
              bigInfo="22%"
              parent
              child
            />
            <S.section center row>
              <Card
                icon={<Currency size={24} color="#777A7E" />}
                subtitle="Fator"
                title="Saúde"
                tag="regular"
                score={2}
                bigInfo="14%"
                child
              />
              <Card
                icon={<DeliveryParcel size={24} color="#777A7E" />}
                subtitle="Fator"
                title="Cobertura"
                tag="crítico"
                score={1}
                bigInfo="30%"
                child
              />
            </S.section>
            <S.section row>
              <Card
                subtitle="Estoque (Dias)"
                title="Cobertura"
                content={
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      gridTemplateColumns: "4fr 1fr",
                    }}
                  >
                    <ProgressBar label="142" completed={100} />
                  </div>
                }
                // content={
                //   <div
                //     style={{
                //       width: "100%",
                //       display: "grid",
                //       gridTemplateColumns: "4fr 1fr",
                //     }}
                //   >
                //     <Bar
                //       data={{
                //         labels: ["SKUs"],
                //         datasets: [
                //           {
                //             label: "SKUs",
                //             data: [142],
                //             backgroundColor: "#41A05F",
                //           },
                //         ],
                //       }}
                //       height={80}
                //       options={{
                //         indexAxis: "y",
                //         maintainAspectRatio: false,
                //         elements: {
                //           bar: {
                //             borderWidth: 0,
                //           },
                //         },
                //         responsive: false,
                //         plugins: {
                //           datalabels: {
                //             color: "#FFFFFF",
                //           },
                //           legend: {
                //             display: false,
                //           },
                //           title: {
                //             display: false,
                //           },
                //         },
                //       }}
                //     />
                //   </div>
                // }
              />
            </S.section>
            <S.section row style={{ gap: "16px" }}>
              <Card
                subtitle="Estoque"
                title="Valor"
                bigInfo={
                  <div style={{ marginLeft: "-8px", marginTop: "10px" }}>
                    R$ 1.245.000
                  </div>
                }
              />
              <Card
                subtitle="Estoque"
                title="Valor"
                bigInfo={
                  <div style={{ marginLeft: "-8px", marginTop: "10px" }}>
                    R$ 750.000
                  </div>
                }
              />
            </S.section>
          </S.section>
        </S.section>
        <S.section>
          <Table />
        </S.section>
      </S.section>
    </LayoutWithMenu>
  );
}
