import styled, { css } from "styled-components";

export const Grid = styled.div`
  display: grid;
  margin: 0 100px;
  grid-template-columns: 1fr 50px 1fr 50px 1fr;
`;

export const Link = styled.div`
  min-height: 80px;
  position: relative;

  &::after {
    ${({ type }) => {
      if (type === "vertical") {
        return css`
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: calc(50% + 2.5px);
          height: 100%;
          border-left: 5px solid white;
        `;
      }
      if (type === "horizontal") {
        return css`
          content: "";
          display: block;
          position: absolute;
          top: calc(50% - 2.5px);
          left: 0;
          width: 100%;
          border-top: 5px solid white;
        `;
      }
      if (type === "right-bottom") {
        return css`
          content: "";
          display: block;
          position: absolute;
          top: calc(50% - 2.5px);
          right: 0;
          width: calc(50% - 2.5px);
          height: calc(50% + 2.5px);
          border-top: 5px solid white;
          border-left: 5px solid white;
        `;
      }
      if (type === "left-bottom") {
        return css`
          content: "";
          display: block;
          position: absolute;
          top: calc(50% - 2.5px);
          left: 0;
          width: calc(50% + 7.5px);
          height: calc(50% + 2.5px);
          border-top: 5px solid white;
          border-right: 5px solid white;
        `;
      }
    }}
  }
`;

export const Card = styled.div`
  padding: 16px;
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: ${({ center }) => (center ? "center" : "flex-start")};

  h3 {
    font-size: 12px;
    font-weight: 400;
    color: #777a7e;
  }

  h4 {
    font-size: 16px;
    font-weight: 500;
    color: #777a7e;
  }

  h6 {
    font-size: 24px;
    font-weight: 600;
  }

  p {
    font-size: 16px;
    font-weight: 600;
  }

  .price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
    margin-top: 16px;
  }

  ${({ gray }) =>
    gray &&
    css`
      background-color: #eaeaeb;
      border-top: 2px solid #dadbdd;
      margin-top: 16px;
    `}
`;

export const Button = styled.button`
  align-items: center;
  background-color: #75fb9f;
  border: none;
  cursor: pointer;
  display: flex;
  font-size: 12px;
  gap: 8px;
  margin-top: 16px;
  padding: 8px;
  width: fit-content;
`;

export const OverTag = styled.div`
  background-color: ${({ isOver }) => (isOver ? "#fc7d84" : "#41A05F")};
  border-radius: 100px;
  color: white;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 16px;
`;

export const GroupCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 300px;
  overflow-y: auto;
`;
