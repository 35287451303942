import React, { useState } from "react";
import { Tabs, TabList, TabPanels, Toggle, ComboBox } from "@carbon/react";
import {
  ArrowRight,
  Calculator,
  ChartBullet,
  CheckmarkFilled,
  CloseFilled,
  Save,
  Undo,
} from "@carbon/icons-react";
import { NumericFormat } from "react-number-format";
import * as S from "./BusinessView.style";
import { faker } from "@faker-js/faker/locale/pt_BR";

const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];
const overstock = [
  null,
  faker.number.int(2000),
  null,
  null,
  faker.number.int(2000),
  null,
  null,
  null,
  null,
  faker.number.int(2000),
  null,
  null,
];
const lackstock = [
  null,
  -faker.number.int(2000),
  null,
  null,
  -faker.number.int(2000),
  null,
  null,
  null,
  null,
  -faker.number.int(2000),
  null,
  null,
];
const sells = Array.from({ length: 12 }, () => -faker.number.int(1000));
const coverage = Array.from({ length: 12 }, () => -faker.number.int(1000));

const textSuggested = () =>
  faker.helpers
    .arrayElements([
      `Incluir compra de ${faker.number.int(
        999
      )} peças em ${faker.date.month()}.`,
      `Transferir ${faker.number.int(999)} peças de ${faker.date.month()}.`,
      `Sugestão ${faker.number.int(999)} peças para ${faker.date.month()}.`,
    ])
    .join(" ");

const slides = [
  {
    title: "Categoria Polo",
    suggested: [
      {
        text: "Transferir 4.502 peças de Abril. Sugestão 2.500 peças para Maio; 2.000 para Junho.",
      },
      { text: "Incluir compra de 700 peças em Junho." },
      { text: "Incluir compra de 2.750 peças em Julho." },
      { text: "Incluir compra de 2.100 peças em Agosto." },
      { text: "Incluir compra de 1.250 peças em Outubro." },
    ],
  },
  {
    title: "Categoria Acessórios",
    suggested: [
      { text: textSuggested() },
      { text: textSuggested() },
      { text: textSuggested() },
    ],
  },
  {
    title: "Categoria Camisas",
    suggested: [
      { text: textSuggested() },
      { text: textSuggested() },
      { text: textSuggested() },
    ],
  },
];

const mockSells = Array.from({ length: 12 }, () => ({
  unit: { value: faker.number.int(100), isChange: false },
  price: { value: faker.number.int(1000), isChange: false },
}));
const mockExpectStock = Array.from(
  { length: 12 },
  () => -faker.number.int(3000)
);
const mockCarteira = Array.from({ length: 12 }, () => -faker.number.int(1000));
const mockReceived = Array.from({ length: 12 }, () => ({
  value: -faker.number.int(1000),
  isChange: false,
}));
const mockCoverage = Array.from({ length: 12 }, () => faker.number.int(3000));
const mockTurn = Array.from({ length: 12 }, () => ({
  value: faker.number.float({ min: 0, max: 2 }),
  isChange: false,
}));
const diff = [
  null,
  null,
  faker.number.int(1000),
  null,
  null,
  null,
  -faker.number.int(1000),
  null,
  null,
  faker.number.int(1000),
  null,
  null,
];

const Link = ({ text, hasConfirm, onSim }) => (
  <S.Link>
    {hasConfirm ? (
      <div>
        <button>
          <CheckmarkFilled size={24} className="confirm" />
        </button>
        <button>
          <CloseFilled size={24} className="reject" />
        </button>
      </div>
    ) : (
      <button className="simulate" onClick={onSim}>
        Simular
      </button>
    )}
    <span>{text}</span>
  </S.Link>
);

const EditPlan = ({ onSavePlan }) => {
  const [sells, setSells] = useState(mockSells);
  const [received, setReceived] = useState(mockReceived);
  const [turn, setTurn] = useState(mockTurn);
  const [disabled, setDisabled] = useState(true);

  const [expectStock, setExpectStock] = useState(mockExpectStock);
  const [carteira, setCarteira] = useState(mockCarteira);
  const [coverage, setCoverage] = useState(mockCoverage);

  return (
    <Tabs defaultSelectedIndex={1}>
      <TabList contained>
        <S.Tab>Plano original</S.Tab>
        <S.Tab>Novo plano</S.Tab>
      </TabList>
      <TabPanels>
        <S.TabPanel>
          <S.Selected>
            <p>Saúde de estoque detalhado</p>
            <ComboBox
              size="sm"
              items={["Polo", "Acessórios", "Camisas", "Jaquetas"]}
            />
          </S.Selected>
          <Toggle
            labelA="Comparar ano passado"
            labelB="Comparar ano passado"
            defaultToggled
            className="toggle"
            size="sm"
          />
          <S.Table>
            <thead>
              <tr>
                <th />
                {months.map((m) => (
                  <th>{m}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Estoque ideal para a venda</th>
                {expectStock.map((o) => (
                  <td>{o}</td>
                ))}
              </tr>
              <tr>
                <th>Excesso/Falta de estoque</th>
                {diff.map((o) => (
                  <td>
                    {o < 0 && (
                      <S.Tag>
                        <div>Falta</div> {o}
                      </S.Tag>
                    )}
                    {o > 0 && (
                      <S.Tag isOver>
                        <div>Excesso</div> {o}
                      </S.Tag>
                    )}
                  </td>
                ))}
              </tr>

              <tr className="start-group">
                <th>Meta de venda</th>
                {mockSells.map(({ unit, price }) => (
                  <td>
                    {(unit.value * price.value).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </td>
                ))}
              </tr>
              <tr>
                <th>Venda em peças</th>
                {mockSells.map(({ unit }) => (
                  <td>
                    <NumericFormat
                      type="number"
                      value={unit.value}
                      decimalScale={0}
                      decimalSeparator=","
                      thousandSeparator="."
                      displayType="text"
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th>Preço médio</th>
                {mockSells.map(({ price }) => (
                  <td>
                    <NumericFormat
                      value={price.value}
                      prefix="R$ "
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale
                      displayType="text"
                    />
                  </td>
                ))}
              </tr>

              <tr className="start-group">
                <th>Carteira</th>
                {carteira.map((value) => (
                  <td>{value}</td>
                ))}
              </tr>
              <tr>
                <th>Projeção de recebimento</th>
                {mockReceived.map(({ value }) => (
                  <td>
                    <NumericFormat
                      type="number"
                      value={value}
                      decimalScale={0}
                      decimalSeparator=","
                      thousandSeparator="."
                      displayType="text"
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th>Estoque final do mês</th>
                {mockReceived.map(({ value }, i) => (
                  <td>
                    <NumericFormat
                      value={value + carteira[i]}
                      displayType="text"
                      decimalScale={0}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale
                    />
                  </td>
                ))}
              </tr>

              <tr className="start-group">
                <th>Cobertura</th>
                {coverage.map((value) => (
                  <td>{value}</td>
                ))}
              </tr>
              <tr>
                <th>Giro</th>
                {mockTurn.map(({ value }) => (
                  <td>
                    <NumericFormat
                      type="number"
                      value={value}
                      decimalScale={1}
                      displayType="text"
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </S.Table>
        </S.TabPanel>
        <S.TabPanel>
          <S.Selected>
            <p>Saúde de estoque detalhado</p>
            <ComboBox
              size="sm"
              items={["Polo", "Acessórios", "Camisas", "Jaquetas"]}
            />
          </S.Selected>
          <Toggle
            labelA="Comparar ano passado"
            labelB="Comparar ano passado"
            defaultToggled
            className="toggle"
            size="sm"
          />
          <S.Table>
            <thead>
              <tr>
                <th />
                {months.map((m) => (
                  <th>{m}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Estoque ideal para a venda</th>
                {expectStock.map((o) => (
                  <td>{o}</td>
                ))}
              </tr>
              <tr>
                <th>Excesso/Falta de estoque</th>
                {diff.map((o) => (
                  <td>
                    {o < 0 && (
                      <S.Tag>
                        <div>Falta</div> {o}
                      </S.Tag>
                    )}
                    {o > 0 && (
                      <S.Tag isOver>
                        <div>Excesso</div> {o}
                      </S.Tag>
                    )}
                  </td>
                ))}
              </tr>

              <tr className="start-group">
                <th>Meta de venda</th>
                {sells.map(({ unit, price }) => (
                  <td>
                    {(unit.value * price.value).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </td>
                ))}
              </tr>
              <tr className="white">
                <th>Venda em peças</th>
                {sells.map(({ unit }, index) => (
                  <td style={{ backgroundColor: unit.isChange && "#DDFEE7" }}>
                    <NumericFormat
                      type="number"
                      value={unit.value}
                      style={{ backgroundColor: unit.isChange && "#DDFEE7" }}
                      decimalScale={0}
                      decimalSeparator=","
                      thousandSeparator="."
                      onValueChange={({ floatValue }) => {
                        setDisabled(false);
                        setSells((sells) =>
                          sells.map((sell, i) =>
                            i === index
                              ? {
                                  ...sell,
                                  unit: { value: floatValue, isChange: true },
                                }
                              : sell
                          )
                        );
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr className="white">
                <th>Preço médio</th>
                {sells.map(({ price }, index) => (
                  <td style={{ backgroundColor: price.isChange && "#DDFEE7" }}>
                    <NumericFormat
                      style={{ backgroundColor: price.isChange && "#DDFEE7" }}
                      value={price.value}
                      prefix="R$ "
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale
                      onValueChange={({ floatValue }) => {
                        setDisabled(false);
                        setSells((sells) =>
                          sells.map((sell, i) =>
                            i === index
                              ? {
                                  ...sell,
                                  price: { value: floatValue, isChange: true },
                                }
                              : sell
                          )
                        );
                      }}
                    />
                  </td>
                ))}
              </tr>

              <tr className="start-group">
                <th>Carteira</th>
                {carteira.map((value) => (
                  <td>{value}</td>
                ))}
              </tr>
              <tr className="white">
                <th>Projeção de recebimento</th>
                {received.map(({ value, isChange }, index) => (
                  <td style={{ backgroundColor: isChange && "#DDFEE7" }}>
                    <NumericFormat
                      style={{ backgroundColor: isChange && "#DDFEE7" }}
                      type="number"
                      value={value}
                      decimalScale={0}
                      decimalSeparator=","
                      thousandSeparator="."
                      onValueChange={({ floatValue }) => {
                        setDisabled(false);
                        setReceived((rec) =>
                          rec.map((r, i) =>
                            i === index
                              ? { value: floatValue, isChange: true }
                              : r
                          )
                        );
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <th>Estoque final do mês</th>
                {received.map(({ value }, i) => (
                  <td>
                    <NumericFormat
                      value={value + carteira[i]}
                      displayType="text"
                      decimalScale={0}
                      decimalSeparator=","
                      thousandSeparator="."
                      fixedDecimalScale
                    />
                  </td>
                ))}
              </tr>

              <tr className="start-group">
                <th>Cobertura</th>
                {mockCoverage.map((value) => (
                  <td>{value}</td>
                ))}
              </tr>
              <tr className="white">
                <th>Giro</th>
                {turn.map(({ value, isChange }, index) => (
                  <td style={{ backgroundColor: isChange && "#DDFEE7" }}>
                    <NumericFormat
                      style={{ backgroundColor: isChange && "#DDFEE7" }}
                      type="number"
                      value={value}
                      decimalScale={1}
                      onValueChange={({ floatValue }) => {
                        setDisabled(false);
                        setTurn((turn) =>
                          turn.map((t, i) =>
                            i === index
                              ? {
                                  value: floatValue,
                                  isChange: true,
                                }
                              : t
                          )
                        );
                      }}
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </S.Table>
          <S.ButtonGroup>
            <S.Button
              disabled={disabled}
              renderIcon={Undo}
              onClick={() => {
                setSells(mockSells);
                setReceived(mockReceived);
                setTurn(mockTurn);
                setDisabled(true);

                setExpectStock(mockExpectStock);
                setCarteira(mockCarteira);
                setCoverage(mockCoverage);
              }}
            >
              Reverter alterações
            </S.Button>
            <S.Button
              green
              disabled={disabled}
              renderIcon={Calculator}
              onClick={() => {
                setExpectStock(
                  Array.from({ length: 12 }, () => -faker.number.int(3000))
                );
                setCarteira(
                  Array.from({ length: 12 }, () => -faker.number.int(1000))
                );
                setCoverage(
                  Array.from({ length: 12 }, () => faker.number.int(3000))
                );
              }}
            >
              Calcular
            </S.Button>
            <S.Button
              green
              disabled={disabled}
              renderIcon={Save}
              onClick={() => {
                onSavePlan();
              }}
            >
              Salvar novo plano
            </S.Button>
          </S.ButtonGroup>
        </S.TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default function BusinessView({ onSavePlan }) {
  const [slide, setSlide] = useState(0);
  const [open, setOpen] = useState(false);
  const [editCategory, setEditCategory] = useState(false);

  return (
    <S.Container>
      <h1>Saúde de estoque resumido - Visão empresa</h1>
      <Toggle
        labelA="Comparar ano passado"
        labelB="Comparar ano passado"
        defaultToggled
        className="toggle"
        size="sm"
      />
      <S.Table>
        <thead>
          <tr>
            <th />
            {months.map((m) => (
              <th>{m}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Excesso</th>
            {overstock.map((o) => (
              <td>
                {o ? (
                  <S.Tag isOver>
                    <div>Excesso</div> {o}
                  </S.Tag>
                ) : (
                  ""
                )}
              </td>
            ))}
          </tr>
          <tr>
            <th>Falta</th>
            {lackstock.map((o) => (
              <td>
                {o ? (
                  <S.Tag>
                    <div>Falta</div> {o}
                  </S.Tag>
                ) : (
                  ""
                )}
              </td>
            ))}
          </tr>
          <tr>
            <th>Venda</th>
            {sells.map((sell) => (
              <td>{sell}</td>
            ))}
          </tr>
          <tr>
            <th>Cobertura</th>
            {coverage.map((c) => (
              <td>{c}</td>
            ))}
          </tr>
        </tbody>
      </S.Table>
      {!editCategory ? (
        <>
          <S.GrayBox>
            <h3>Ações Sugeridas:</h3>
            <S.Carrousel slide={slide}>
              <button
                className="prev"
                disabled={slide === 0}
                onClick={() => {
                  setSlide((slide) => slide - 1);
                }}
              />
              <button
                className="next"
                disabled={slide === slides.length - 1}
                onClick={() => {
                  setSlide((slide) => slide + 1);
                }}
              />
              <div className="presentation">
                {slides.map(({ title, suggested }) => (
                  <S.Item slide={slide}>
                    <h6>{title}</h6>
                    {suggested?.map(({ text }) => (
                      <Link text={text} onSim={() => setOpen(true)} />
                    )) || ""}
                  </S.Item>
                ))}
              </div>
              <div className="markGroup">
                {slides.map((_, i) => (
                  <div className={`mark ${slide === i ? "active" : ""}`} />
                ))}
              </div>
            </S.Carrousel>
          </S.GrayBox>
          <S.Button renderIcon={ChartBullet} onClick={() => setOpen(true)}>
            Otimize automaticamente
          </S.Button>
          <S.Modal
            open={open}
            onRequestClose={() => setOpen(false)}
            isFullWidth
            passiveModal
            modalHeading="Bem-vindo a edição do seu plano"
          >
            Você poderá simular mudanças no seu plano de acordo com as ações
            sugeridas ou editar você mesmo manualmente. Nome do plano Dê um nome
            ao novo plano.
            <S.ButtonGroup>
              <S.Button renderIcon={Undo} onClick={() => setOpen(false)}>
                Cancelar
              </S.Button>
              <S.Button
                green
                renderIcon={ArrowRight}
                onClick={() => setEditCategory(true)}
              >
                Continuar
              </S.Button>
            </S.ButtonGroup>
          </S.Modal>
        </>
      ) : (
        <>
          <S.GrayBox>
            <h3>Ações Sugeridas:</h3>
            <S.Item>
              <h6>{slides[slide]?.title}</h6>
              {slides[slide].suggested?.map(({ text }) => (
                <Link text={text} hasConfirm />
              )) || ""}
            </S.Item>
          </S.GrayBox>

          <EditPlan onSavePlan={onSavePlan} />
        </>
      )}
    </S.Container>
  );
}
