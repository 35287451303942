import React from "react";
import {
  Column,
  Grid,
  DataTable,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableExpandHeader,
  TableBody,
  TableExpandRow,
  TableCell,
  TableExpandedRow,
  TextInput,
} from "@carbon/react";
import { ArrowRight, ArrowLeft } from "@carbon/icons-react";

import Icon from "../../../assets/icons/Delivery--truck.svg";
import * as S from "./LeadTimeStep.style";

export default function LeadTimeStep({ onCompleteStep, onBackStep }) {
  const headers = [
    { title: "Categorias", key: "category" },
    { title: "Lead time", key: "leadTime" },
  ];

  const externalData = [
    {
      id: "row-1",
      category: "Camisetas",
      leadTime: undefined,
      products: [
        { id: "01234", name: "Camiseta 1" },
        { id: "01234", name: "Camiseta 1" },
        { id: "01234", name: "Camiseta 1" },
      ],
    },
    {
      id: "row-2",
      category: "Jaquetas",
      leadTime: 3,
      products: [
        { id: "09829", name: "Jaqueta jeans longa azul bolso frontal" },
        { id: "09829", name: "Jaqueta jeans longa azul bolso frontal" },
        { id: "09829", name: "Jaqueta jeans longa azul bolso frontal" },
        { id: "09829", name: "Jaqueta jeans longa azul bolso frontal" },
        { id: "09829", name: "Jaqueta jeans longa azul bolso frontal" },
        { id: "09829", name: "Jaqueta jeans longa azul bolso frontal" },
        { id: "09829", name: "Jaqueta jeans longa azul bolso frontal" },
      ],
    },
    {
      id: "row-3",
      category: "Calças",
      leadTime: 5,
      products: [
        { id: "09829", name: "Calças" },
        { id: "09829", name: "Calças" },
      ],
    },
    {
      id: "row-4",
      category: "Bermudas",
      leadTime: 7,
      products: [
        { id: "09829", name: "Bermudas" },
        { id: "09829", name: "Bermudas" },
      ],
    },
    {
      id: "row-5",
      category: "Acessórios",
      leadTime: 12,
      products: [
        { id: "09829", name: "Acessórios" },
        { id: "09829", name: "Acessórios" },
      ],
    },
  ];

  return (
    <Grid style={{ marginTop: 60 }}>
      <Column lg={8} md={4} sm={4}>
        <S.Title>
          <img src={Icon} alt="Tempo de entrega" />
          <h4>Tempo de entrega</h4>
        </S.Title>
        <S.Text>
          Agora você vai definir uma média de lead time por categoria. Pense no
          tempo em semanas entre fazer o pedido ao fornecedor e receber esse
          pedido em loja. Com esses inputs você poderá otimizar seus níveis de
          estoque, garantindo que seus produtos estejam disponíveis quando e
          onde são necessários. Utilizamos as categorias cadastradas para seus
          itens.
        </S.Text>
      </Column>
      <Column
        lg={8}
        md={4}
        sm={4}
        style={{
          backgroundColor: "#0000000A",
          padding: "24px 72px",
        }}
      >
        <S.Text>
          Qual a média de semanas que leva desde você encomendar o produto com o
          fornecedor até ele chegar em loja?
        </S.Text>
        <DataTable rows={externalData} headers={headers}>
          {({
            rows,
            headers,
            getHeaderProps,
            getRowProps,
            getTableProps,
            getTableContainerProps,
          }) => (
            <TableContainer {...getTableContainerProps()}>
              <S.Table {...getTableProps()} size="md">
                <TableHead>
                  <TableRow>
                    <TableExpandHeader id="expand" />
                    {headers.map((header, i) => (
                      <TableHeader
                        id={header.title}
                        {...getHeaderProps({ header })}
                      >
                        {header.title}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, rowIdx) => {
                    console.log(getRowProps({ row }));
                    return (
                      <React.Fragment key={row.id}>
                        <TableExpandRow {...getRowProps({ row })}>
                          <TableCell>
                            {rows[rowIdx].cells[0].value || "Categoria"}
                          </TableCell>
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <TextInput
                                placeholder="Tempo em dias"
                                value={rows[rowIdx].cells[1].value}
                              />{" "}
                              dias
                            </div>
                          </TableCell>
                        </TableExpandRow>
                        <TableExpandedRow colSpan={headers.length + 1}>
                          {console.log(row)}
                          {externalData[rowIdx].products?.map((product) => (
                            <S.Product key={product.id}>
                              {product.id} - {product.name}
                            </S.Product>
                          ))}
                        </TableExpandedRow>
                      </React.Fragment>
                    );
                  })}
                </TableBody>
              </S.Table>
            </TableContainer>
          )}
        </DataTable>
        <S.ButtonSet>
          <S.Button kind="ghost">Editar categorias</S.Button>
          <S.Button
            kind="secondary"
            onClick={() => {
              onBackStep(2);
            }}
          >
            <ArrowLeft /> Voltar
          </S.Button>
          <S.Button
            primary
            renderIcon={() => <ArrowRight />}
            onClick={() => {
              onCompleteStep(2);
            }}
          >
            Próxima
          </S.Button>
        </S.ButtonSet>
      </Column>
    </Grid>
  );
}
