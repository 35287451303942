import styled, { css } from "styled-components";
import { Tab as CTab } from "@carbon/react";

export const Section = styled.section``;

export const Title = styled.article`
  h3 {
    font-size: 32px;
    font-weight: 600;
  }

  display: flex;
  gap: 24px;
  padding: 24px 0;
  align-items: center;
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #7d7d7d;
`;

export const Tab = styled(CTab)`
  ${(props) => {
    if (props.completed) {
      return css`
        color: #5bce7f !important;
        border-block-end: 2px solid #5bce7f !important;
      `;
    }
    if (props.selected) {
      return css`
        border-block-end: 2px solid #000 !important;
      `;
    }
  }};
`;