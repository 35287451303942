import React, { useState } from "react";
import {
  Column,
  DatePicker,
  DatePickerInput,
  Grid,
  TextInput,
} from "@carbon/react";
import { ArrowRight, ArrowLeft } from "@carbon/icons-react";

import Icon from "../../../assets/icons/calendar--event.svg";
import * as S from "./CommercialDaysStep.style";

export default function CommercialDaysStep({ onCompleteStep, onBackStep }) {
  const [commercialDays, setCommercialDays] = useState([
    { day: 9, mounth: 3, name: "Páscoa" },
    { day: 15, mounth: 2, name: "Dia do Consumidor" },
    { day: 12, mounth: 5, name: "Dia dos Namorados" },
    { day: 14, mounth: 4, name: "Dia das Mães" },
    { day: 11, mounth: 7, name: "Dia dos Pais" },
    { day: 29, mounth: 10, name: "Black friday" },
  ]);

  return (
    <Grid style={{ marginTop: 60 }}>
      <Column lg={8} md={4} sm={4}>
        <S.Title>
          <img src={Icon} alt="Datas comerciais" />
          <h4>Datas comerciais</h4>
        </S.Title>
        <S.Text>
          As datas comerciais desempenham um papel crucial para o varejo,
          representando oportunidades estratégicas para impulsionar as vendas
          através de um aumento no fluxo de consumo. Tenha um olhar mais apurado
          sobre elas selecionando aquelas que se alinham de maneira
          significativa ao perfil do seu negócio. Você também pode adicionar
          datas personalizadas.
        </S.Text>
      </Column>
      <S.Column lg={8} md={4} sm={4}>
        <S.Text black>
          Selecione as Datas Comerciais nas quais tem interesse
        </S.Text>
        <S.Taglist>
          {commercialDays.map((date, index) => (
            <S.Tag
              isSelected={date?.selected}
              onClick={() => {
                const newCommercialDays = commercialDays.map((item, i) => {
                  if (i === index) {
                    return {
                      ...item,
                      selected: !item.selected,
                    };
                  }
                  return item;
                });
                setCommercialDays(newCommercialDays);
              }}
            >
              {new Date(
                new Date().getFullYear(),
                date.mounth,
                date.day
              ).toLocaleString("pt-BR", {
                formatMatcher: "best fit",
                month: "numeric",
                day: "numeric",
              })}{" "}
              - {date.name}
            </S.Tag>
          ))}
        </S.Taglist>

        <S.Text black>Inserir data comercial personalizada</S.Text>
        <S.List>
          <TextInput
            labelText="Nome da data comercial"
            placeholder="Digite o nome"
          />
          <DatePicker datePickerType="single">
            <DatePickerInput
              placeholder="mm/dd/AAAA"
              labelText="Date Picker label"
            />
          </DatePicker>
        </S.List>

        <S.ButtonSet>
          <S.Button
            kind="secondary"
            onClick={() => {
              onBackStep(3);
            }}
          >
            <ArrowLeft /> Voltar
          </S.Button>
          <S.Button
            primary
            renderIcon={() => <ArrowRight />}
            onClick={() => {
              onCompleteStep(3);
            }}
          >
            Próxima
          </S.Button>
        </S.ButtonSet>
      </S.Column>
    </Grid>
  );
}
