import React, { useState } from "react";
import {
  Column,
  DatePicker,
  DatePickerInput,
  Grid,
  TextInput,
} from "@carbon/react";
import { AddAlt, ArrowRight, MisuseOutline } from "@carbon/icons-react";

import Chart from "../../../assets/icons/Chart--bar.svg";
import * as S from "./CreateSeasonStep.style";

export default function CreateSeasonStep({ onCompleteStep }) {
  const [season, setSeason] = useState([{}]);

  return (
    <Grid style={{ marginTop: 60 }}>
      <Column lg={8} md={4} sm={4}>
        <S.Title>
          <img src={Chart} alt="Período de Planejamento" />
          <h4>Período de planejamento</h4>
        </S.Title>
        <S.Text>
          Para iniciar seu planejamento estratégico de vendas e estoque você
          precisa delimitar o período para o qual irá se planejar e sua meta de
          crescimento durante esse intervalo. Nós te enviaremos dicas e você
          poderá fazer ajustes de rota de acordo com suas vendas. Quando esse
          período estiver perto de acabar nós te avisaremos para criar o
          próximo.
        </S.Text>
      </Column>
      <Column
        lg={8}
        md={4}
        sm={4}
        style={{
          backgroundColor: "#0000000A",
          padding: "24px 72px",
        }}
      >
        <S.Text>
          Selecione o período para o qual você deseja fazer seu planejamento e
          qual a projeção do valor que você deseja atingir dentro desse período.
          Se preferir, divida o planejamento em mais de um período.
        </S.Text>

        {season.map((item, index) => (
          <S.Inputs>
            <DatePicker
              value={[item?.startDate, item?.endDate]}
              datePickerType="range"
              dateFormat="d/m/Y"
              onChange={([startDate, endDate]) => {
                setSeason((season) => {
                  const newSeason = [...season];
                  newSeason[index] = {
                    ...newSeason[index],
                    startDate,
                    endDate,
                  };
                  return newSeason;
                });
              }}
            >
              <DatePickerInput
                placeholder="dd/mm/yyyy"
                labelText="Início do Período"
              />
              <DatePickerInput
                placeholder="dd/mm/yyyy"
                labelText="Final do Período"
              />
            </DatePicker>

            <TextInput
              labelText="Nome"
              value={item?.name}
              onChange={(e) => {
                setSeason((season) => {
                  const newSeason = [...season];
                  newSeason[index] = {
                    ...newSeason[index],
                    name: e.target.value,
                  };
                  return newSeason;
                });
              }}
            />

            <TextInput
              labelText="Meta"
              value={item?.meta}
              onChange={(e) => {
                setSeason((season) => {
                  const newSeason = [...season];
                  newSeason[index] = {
                    ...newSeason[index],
                    meta: e.target.value,
                  };
                  return newSeason;
                });
              }}
            />
          </S.Inputs>
        ))}

        <S.Button
          kind="tertiary"
          size="sm"
          onClick={() => {
            setSeason((season) => [...season, {}]);
          }}
        >
          <span class="icon">
            <AddAlt />
          </span>
          adicionar período
        </S.Button>

        <S.ButtonSet>
          <S.Button
            kind="secondary"
            renderIcon={() => <MisuseOutline />}
            onClick={() => {
              setSeason([{}]);
            }}
          >
            Cancelar
          </S.Button>
          <S.Button
            primary
            renderIcon={() => <ArrowRight />}
            onClick={() => {
              onCompleteStep(0);
            }}
          >
            Próxima
          </S.Button>
        </S.ButtonSet>
      </Column>
    </Grid>
  );
}
