import React from "react";
import { ComposedModal, Button, ModalHeader } from "@carbon/react";
import {
  ShoppingCart,
  Flash,
  HealthCross,
  Box,
  Currency,
  Star,
  StarFilled,
  FlashFilled,
} from "@carbon/icons-react";

import * as S from "./modalDetalhe.style";
import { BoxFilled, HealthCrossFilled } from "../LocalIcons";

const mockDialig = [
  {
    title: "Performance de Venda",
    text: "Item com ótima performance, porém com oportunidade de aprimorar margem.",
    status: "Regular",
    icon: Star,
    filledIcon: StarFilled,
  },
  {
    title: "Velocidade de Venda",
    text: "Giro acima do ideal. Oportunidade de ajuste de preço.",
    status: "Critica",
    icon: Flash,
    filledIcon: FlashFilled,
  },
  {
    title: "Saúde do Estoque",
    text: "92% do estoque com menos de 6 semanas",
    status: "Ótimo",
    icon: HealthCross,
    filledIcon: HealthCrossFilled,
  },
  {
    title: "Cobertura do Estoque",
    text: "Item com apenas 2 semanas de cobertura",
    status: "Critica",
    icon: Box,
    filledIcon: BoxFilled,
  },
];

export default function ModalDetalhe({ open, item, setShow }) {
  const accordingStatus = (status, Icons, FilledIcons) => {
    switch (status) {
      case "Excelente": {
        return (
          <div className="icons">
            <span className="text-status-icon">{status}</span>
            <FilledIcons />
            <FilledIcons />
            <FilledIcons />
            <FilledIcons />
          </div>
        );
      }
      case "Ótimo": {
        return (
          <div className="icons">
            <span className="text-status-icon">{status}</span>
            <FilledIcons />
            <FilledIcons />
            <FilledIcons />
            <Icons />
          </div>
        );
      }
      case "Regular": {
        return (
          <div className="icons">
            <span className="text-status-icon">{status}</span>
            <FilledIcons />
            <FilledIcons />
            <Icons />
            <Icons />
          </div>
        );
      }
      case "Critica": {
        return (
          <div className="icons">
            <span className="text-status-icon">{status}</span>
            <FilledIcons />
            <Icons />
            <Icons />
            <Icons />
          </div>
        );
      }

      default: {
        return null;
      }
    }
  };

  if (!item)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2>Ops</h2>
      </div>
    );
  const {
    sku,
    receita,
    receitaShare,
    crescReceita,
    precoVenda,
    sharePrecoVenda,
    estoquePÇ,
    estoqueRS,
    cobertura,
  } = item;
  return (
    <S.content>
      <ComposedModal open={open} onClose={() => setShow(false)}>
        <ModalHeader>
          <S.headerTitle>
            <span className="id">{sku.sku}</span>
            <h3>{sku.name}</h3>

            <span className="legend">{sku.corSize}</span>
          </S.headerTitle>
        </ModalHeader>
        <S.contentModal>
          <h5 className="m-b-5">Informaçoes Gerais</h5>
          <S.contentInputsValues>
            <S.frameInfoAndValue>
              <span>Receita (RS)</span>
              <strong>{receita}</strong>
            </S.frameInfoAndValue>
            <S.frameInfoAndValue>
              <span>Receita (Share)</span>
              <strong>{receitaShare}</strong>
            </S.frameInfoAndValue>
            <S.frameInfoAndValue>
              <span>Cresc. Receita vs Último Ano</span>
              <strong>{crescReceita}</strong>
            </S.frameInfoAndValue>
            <S.frameInfoAndValue>
              <span>Preço Médio de Venda (R$)</span>
              <strong>{precoVenda}</strong>
            </S.frameInfoAndValue>

            <S.frameInfoAndValue>
              <span>Share Valor de Venda (%)</span>
              <strong>{sharePrecoVenda}</strong>
            </S.frameInfoAndValue>

            <S.frameInfoAndValue>
              <span>Estoque (Pçs)</span>
              <strong>{estoquePÇ}</strong>
            </S.frameInfoAndValue>

            <S.frameInfoAndValue>
              <span>Estoque (R$)</span>
              <strong>{estoqueRS}</strong>
            </S.frameInfoAndValue>

            <S.frameInfoAndValue>
              <span>Cobertura (Dias)</span>
              <strong>{cobertura}</strong>
            </S.frameInfoAndValue>
          </S.contentInputsValues>
        </S.contentModal>
        <S.contentModal>
          <h5 className="m-b-5">Fatores Sumply</h5>
          <div>
            <S.contentDialog>
              {mockDialig.map((item) => (
                <S.dialog key={item.status}>
                  <S.dialogTitle>
                    <strong className="title">{item.title}</strong>
                    <div>
                      {accordingStatus(item.status, item.icon, item.filledIcon)}
                    </div>
                  </S.dialogTitle>
                  <S.dialogText>{item.text}</S.dialogText>
                </S.dialog>
              ))}
            </S.contentDialog>
          </div>
        </S.contentModal>
      </ComposedModal>
    </S.content>
  );
}
